export const searchTemplates = () => {
  return `query TemplateManagement($template: SearchTemplateRequestInputType!, $userInfo: UserInformationInputType) {
        searchTemplate(template: $template, userInfo: $userInfo) {
          searchTemplateResult {
            mxTemplateID
            mxTemplateName
            station
            checkType
            fleetType
            templateType
            tailNumber
            visitID
            inductionDate
            etrDate
            duration
            taskcardCount
            milestoneCount
            lastUpdated
            lastUpdatedBy
          }
        }
      }
      `
}

export const fetchTemplateDetails = () => {
  return `query TemplateManagement($templateDetails: ViewDetailsInputType!, $userInfo: UserInformationInputType) {
    viewTemplateDetails(templateDetails: $templateDetails, userInfo: $userInfo) {
      visitID
      duration
      station
      tailNumber
      milestoneCount
      routineTotalCount
      overrideGroupsCount
      unusedGroupsCount
      scheduledGroupsCount
      unscheduledGroupsCount
      routineScheduledCount
      routineUnScheduledCount
      workBinTotalCount
      mxGroups {
        groupId
        groupName
        dash8s {
          dash8Number
          workControlNumber
          serialNumber
          dash8Description
          dash1s {
            mxType
            cardNumber
            taskTitle
            groupOverride
            workControlNumber
            dash8Number
            shiftStartNumber
            shiftEndNumber
            mxMasterId
            isPaper
            isMissingProperties
            missingProperties
              {
               workArea
                taskCardType
                mileStone
                skills
              }

          }
        }        
        shiftStartNumber
        shiftEndNumber
      }
      checkType
      fleetType
      mxTemplateName
      mxTemplateId
    }
  }
  `
}

export const getTemplateTaskCardDetails = () => {
  return `query TemplateManagement($taskcardDetails: GetTemplateDetailsInputType, $userInfo: UserInformationInputType) {
    getTemplateTaskCardDetails(
      taskcardDetails: $taskcardDetails
      userInfo: $userInfo
    ) {
      taskCardDetailsOverview {
        id
        masterID
        duration
        description
        cardNumber
        cardNumberUI
        manualReference
        workAreaID
        zoneTypeID
        closingRequirement
        taskCardTypeID
        priorityID
        serialNumber
        positionCode
      }
      targetMileStone {
        mileStoneID
        groupName
        startShift
        endShift
        shiftEndNumber
        overrideShift
      }
      taskCardDependencies {
        dependencyID
        dependencyMasterID
        dependencyCardNumber
        dependencyDescription
        dependencyType
        dash1Status
        uIDash1Status
        zoneCardNumber
        zoneId
        workControlNumber
        serialNumber
        mxType
        dash8
        nonRoutineStatusId
        masterId
      }
      taskCardEstimatedHours {
        estimatedHourID
        skillID
        manHours
        resourcesRequired
      }
      workBins {
        workBinId
        workBinName
      }      
      assignedCrews{
        crewConfigId
        crewName
      }
      isTaskcardStandard 
      preferredDescription
      zone
      tcmDetails {
        isTCMEnabled
        specNumber
      }
    }
  }`
}


export const fetchTemplateManagementRefData = () => {
  return `query TemplateManagement($userInfo: UserInformationInputType) {
    getRefData(userInfo: $userInfo) {
      priority {
        priorityID
        priorityName
        isActive
      }
      skills {
        skillID
        skillType
        isActive
      }
      taskcardType {
       taskcardTypeID
        taskcardTypeName
        isActive
      }
      workArea
      {
        workArea
        workAreaID
        isActive
      }
      zoneTypes {
        zoneTypeId
        zoneType
        isActive
        zoneLevelID
        isSelectable
      }
      subZoneTypes {
        subZoneTypeId
        subZoneTypeLevel
        zoneTypeId
        zoneTypeIdSub
        isActive
        zoneType
        subZoneType
        isSelectable
      }  
    }
  }`
}

export const fetchTemplateManagementGroups = () => {
  return `query TemplateManagement($request: GetGroupsInputType, $userInfo: UserInformationInputType) {
    getGroups(request: $request, userInfo: $userInfo) {
      groups {
        groupName
        mileStoneID
        shiftStartNumber
        shiftEndNumber
      }
    }
  }`
}

// export const searchTaskcards = () => {
//   return `query TemplateManagement($request: SearchTaskcardsRequestType, $userInfo: UserInformationInputType) {
//     searchTaskcards(request: $request, userInfo: $userInfo) {
//       searchTaskCards {
//         id
//         masterId
//         mxItem
//         mxType
//         mxItemDescription
//         actionType
//         zoneId
//         zoneCardId
//         zoneCardNumber
//         workArea
//         priorityID
//         taskcardTypeID
//         workAreaID
//         groupName
//         shiftEndNumber
//         dash8
//         workControlNumber
//         serialNumber
//         positionCode
//         rootId
//         cardId
//         cardDocType
//         schedArrDate
//         taskCardMasterID
//         isMissingProperties
//         missingProperties
//         {
//           workArea
//           taskCardType
//           mileStone
//           skills
//         }
//         skills {
//           skillId
//           skillType
//           skillTypeCode
//         }
//         workStop
//         workStopActionType
//         workStopActionTypeAbbreviated  
//       }
//     }
//   }
// `
// }

export const searchTaskcardsMultiSelect = () => {
  return `query TemplateManagement($request: SearchTaskcardsRequestMultiSelectType, $userInfo: UserInformationInputType) {
    searchTaskcardsMultiSelect(request: $request, userInfo: $userInfo) {
      searchTaskCards {
        id
        masterId
        mxItem
        mxType
        mxItemDescription
        actionType
        zoneId
        zoneCardId
        zoneCardNumber
        workArea
        priorityID
        taskcardTypeID
        workAreaID
        groupName
        shiftEndNumber
        dash8
        workControlNumber
        serialNumber
        positionCode
        rootId
        cardId
        cardDocType
        schedArrDate
        taskCardMasterID
        isMissingProperties
        missingProperties
        {
          workArea
          taskCardType
          mileStone
          skills
        }
        skills {
          skillId
          skillType
          skillTypeCode
        }
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
        groupId
        optimizedGroup
        optimizedDetails {
          optimizedReason
          optimizedReferenceInfo {
            optimizedDash8s {
              ncaPartNumber
              ncaSerialNumber
              forecastDescription
              positionCode
              formattedDash8
            }
            optimizedEffectivity
          }
          optimizedVersion
        }
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        uIDash1Status
        isDash1Duplicate
        tcmDetails {
          isTCMEnabled
          specNumber
        }
        isPaper
      }
    }
  }
`
}

export const searchTaskcardsMultiSelectEnhanced = () => {
  return `query TemplateManagement($request: SearchTaskcardsMultiSelectEnhancedType, $userInfo: UserInformationInputType) {
    searchTaskcardsMultiSelectEnhanced(request: $request, userInfo: $userInfo) {
      searchTaskCards {
        id
        masterId
        mxItem
        mxType
        mxItemDescription
        actionType
        zoneId
        zoneCardId
        zoneCardNumber
        workArea
        priorityID
        taskcardTypeID
        workAreaID
        groupName
        shiftEndNumber
        dash8
        workControlNumber
        serialNumber
        positionCode
        rootId
        cardId
        cardDocType
        schedArrDate
        taskCardMasterID
        isMissingProperties
        missingProperties
        {
          workArea
          taskCardType
          mileStone
          skills
        }
        skills {
          skillId
          skillType
          skillTypeCode
        }
        workStop
        workStopActionType
        workStopActionTypeAbbreviated
        groupId
        optimizedGroup
        optimizedDetails {
          optimizedReason
          optimizedReferenceInfo {
            optimizedDash8s {
              ncaPartNumber
              ncaSerialNumber
              forecastDescription
              positionCode
              formattedDash8
            }
            optimizedEffectivity
          }
          optimizedVersion
        }
        dash1OptimizedStatusType
        isOptimized
        dash1Status
        uIDash1Status
        isDash1Duplicate
        nonRoutineStatusId
      }
    }
  }
`
}

export const fetchTemplateMilestones = () => {
  return `query TemplateManagement($request: GetTemplateMilestonesInputType, $userInfo: UserInformationInputType) {
    getTemplateMilestones(request: $request, userInfo: $userInfo) {
      templateMilestonesDetails {
        groupId
        groupName
      }
    id
    isSuccess
    error
    }
  }
`
}

export const saveTemplateTaskCardDetails = () => {
  return `mutation TemplateManagement($saveTaskcardDetails: SaveTemplateDetailsInputType, $userInfo: UserInformationInputType) {
    saveTemplateTaskCardDetails(saveTaskcardDetails: $saveTaskcardDetails, userInfo: $userInfo) {
      returnId
      error
      isSuccess
      id
    }
  }`
}

export const saveUpdateMilestone = () => {
  return `mutation TemplateManagement($saveTemplateGroup: SaveGroupRequestInputType!,
    $userInfo: UserInformationInputType) {
    saveGroup(saveTemplateGroup: $saveTemplateGroup, userInfo: $userInfo) 
      {
        groupId
        isSuccess
        error
      }
  }
  `
}

export const saveTemplate = () => {
  return `mutation TemplateManagement($saveTemplate: SaveAsTemplateInputType, $userInfo: UserInformationInputType) {
    saveAsTemplate(saveTemplate: $saveTemplate, userInfo: $userInfo) {
      mxTemplateId
      id
      isSuccess
      error
    }
  }`
}

export const applyTemplate = () => {
  return `mutation TemplateManagement($applyTemplate: ApplyTemplateInputType, $userInfo: UserInformationInputType) {
    applyTemplate(applyTemplate: $applyTemplate, userInfo: $userInfo) {
      isSuccess
      errors {
        code
        type
        description
      }
    }
  }`
}

export const updateTemplate = () => {
  return `mutation TemplateManagement($updateTemplate: UpdateTemplateFromVisitInputType, $userInfo: UserInformationInputType) {
    updateTemplateFromVisit(updateTemplate: $updateTemplate, userInfo: $userInfo) {
    insertedGroups
    updatedMxItemGroups
    insertedMxItems
    id
    isSuccess
    error
    }
    }
  `
}

export const updateDetails = () => {
  return `mutation TemplateManagement($updateDetails: UpdateTemplateDetailsRequestInputType!, $userInfo: UserInformationInputType) {
    updateTemplateDetails(updateDetails: $updateDetails, userInfo: $userInfo)  {
    isSuccess
    error
    }
    }
  `
}

export const deleteTemplate = () => {
  return `mutation TemplateManagement($deleteTemplate: DeleteTemplateInputType, $userInfo: UserInformationInputType) {
    deleteTemplate(deleteTemplate: $deleteTemplate, userInfo: $userInfo) {
      isSuccess
      id
      error
      errors
      {
        code
        type
        description
      }
      passwordErrorMessage
    }
  }`
}

