import {
    SEARCH_TEMPLATES, RESET_TEMPLATE_DATA, SET_TEMPLATE_SEARCH_CRITERIA,
    FETCH_TEMPLATE_DETAILS, RESET_TEMPLATE_DETAILS, SET_SELECTED_TEMPLATE_GROUP, GET_TEMPLATE_TASKCARD_DETAILS, SEARCH_TASKCARDS, SEARCH_TASKCARDS_ENHANCED,
    SAVE_TEMPLATE_TASKCARD_DETAILS, GET_TEMPLATE_MANAGEMENT_REF_DATA, GET_TEMPLATE_MANAGEMENT_GROUPS_DATA,
    RESET_TASK_CARD_DETAILS_ERROR_MESSAGE, SAVE_UPDATE_MILESTONE, SET_TASKCARD_SEARCH_CRITERIA, RESET_TASKCARD_SEARCH_DATA,
    RESET_SAVE_UPDATE_MILESTONE, APPLY_TEMPLATE, SAVE_TEMPLATE, RESET_SAVE_TEMPLATE_ERROR_MESSAGE, RESET_APPLY_TEMPLATE,
    SET_UPDATE_TEMPLATE_FLAG, UPDATE_TEMPLATE, UPDATE_TEMPLATE_DETAILS, DELETE_TEMPLATE, FETCH_TEMPLATE_MILESTONES
} from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    isSearchingTemplates: false,
    templates: null,
    searchCriteria: {
        searchType: { value: "Default", isValid: true },
        station: { value: "", isValid: true },
        checkType: { value: "", isValid: true },
        fleetCode: { value: "", isValid: true },
        templateName: { value: "", isValid: true },
        tailNumber: { value: "", isValid: true },
        duration: { value: null, isValid: true },
        startDate: { value: null, isValid: true },
        endDate: { value: null, isValid: true },
        isSearchValid: false,
        defaultSearchParams: [],
        visitSearchParams: [],
    },
    isLoadingTemplateDetails: true,
    isLoadingTemplateMilestones: true,
    templateDetails: null,
    milestonesData: null,
    templateMilestones: null,
    selectedTemplateGroup: null,
    otherMilestone: null,
    templateShifts: null,
    visitSearchParams: [],
    taskcardDetails: null,
    taskCardSearchCriteria: {
        searchType: { value: "Template", isValid: false },
        taskCardNumber: { value: "", isValid: false },
        description: { value: "", isValid: false },
        workArea: { value: "", isValid: false },
        taskCardTypes: { value: [], isValid: false },
        skillIds: { value: [], isValid: false },
        workBinIds: { value: [], isValid: false },
        mileStoneIds: { value: [], isValid: false },
        isSearchValid: false,
        defaultSearchParams: [],
        visitSearchParams: [],
        zoneTypeIDs: { value: [], isValid: false }
    },
    isSearchingTaskcards: false,
    taskCardResults: null,
    isTaskcardDetailsSavePending: false,
    isTaskcardDetailsSaveSuccess: false,
    taskcardDetailsErrorMessage: null,
    isLoadingRefData: false,
    templateManagementRefData: null,
    isLoadingGroupsData: false,
    groupsData: null,
    isSavetaskcardDetailsSuccess: false,
    isSavetaskcardDetailsError: false,
    saveUpdateMilestoneResp: {},
    saveUpdateMilestoneLoading: false,
    isApplyingTemplate: false,
    applyTemplateResponse: null,
    isSaveSuccess: false,
    isSavingTemplate: false,
    savedTemplateId: null,
    isSaveTemplateError: false,
    saveTemplateErrorMessage: null,
    isUpdateTemplateModalView: false,
    updateTemplateResponse: null,
    crewGroups: []
}

const getTemplateShifts = duration => {
    let shifts = [];
    for (let i = 1; i <= duration * 3; i++) {
        shifts.push({
            shiftNumber: i,
            shiftType: i % 3 === 1 ? "Day" : i % 3 === 2 ? "Swing" : "Grave",
            shiftDate: moment().add('days', Math.floor(((i - 1) / 3) + 1)).format("MMM DD"),
            shiftDay: "Day " + (Math.floor((i - 1) / 3) + 1),
            isCurrentShift: false
        })
    }
    return shifts;
}
const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${SEARCH_TEMPLATES}_PENDING`: {
            return {
                ...state,
                isSearchingTemplates: true,
                templates: null
            }
        }
        case `${SEARCH_TEMPLATES}_FULFILLED`: {
            return {
                ...state,
                isSearchingTemplates: false,
                templates: (action.payload && action.payload.searchTemplate) ? action.payload.searchTemplate.searchTemplateResult : []
            }
        }
        case `${RESET_TEMPLATE_DATA}`: {
            return {
                ...state,
                isSearchingTemplates: false,
                templates: null,
                searchCriteria: {
                    searchType: { value: "Default", isValid: true },
                    station: { value: "", isValid: true },
                    checkType: { value: "", isValid: true },
                    fleetCode: { value: "", isValid: true },
                    templateName: { value: "", isValid: true },
                    tailNumber: { value: "", isValid: true },
                    duration: { value: null, isValid: true },
                    startDate: { value: null, isValid: true },
                    endDate: { value: null, isValid: true },
                    isSearchValid: false,
                    defaultSearchParams: [],
                    visitSearchParams: []
                }
            }
        }
        case `${SET_TEMPLATE_SEARCH_CRITERIA}`: {
            return {
                ...state,
                searchCriteria: action.payload
            }
        }
        case `${FETCH_TEMPLATE_DETAILS}_PENDING`: {
            return {
                ...state,
                isLoadingTemplateDetails: true,
                templateDetails: null
            }
        }
        case `${FETCH_TEMPLATE_DETAILS}_FULFILLED`: {
            let templateInfo = (action.payload && action.payload.viewTemplateDetails) ? action.payload.viewTemplateDetails : null;
            const selectedGroupInfo = state.selectedTemplateGroup && templateInfo ? templateInfo.mxGroups.find(grp => grp.groupId === state.selectedTemplateGroup.groupId) : null;
            let selectedGroup = state.selectedTemplateGroup ? {
                groupId: state.selectedTemplateGroup.groupId,
                isScheduled: selectedGroupInfo?.shiftStartNumber !== null && selectedGroupInfo?.shiftStartNumber !== 0 && selectedGroupInfo?.shiftEndNumber !== null && selectedGroupInfo?.shiftEndNumber !== 0,
                groupInfo: selectedGroupInfo ? selectedGroupInfo : null,
                startShiftInfo: selectedGroupInfo?.shiftStartNumber && state.templateShifts?.find(vs => vs.shiftNumber == selectedGroupInfo.shiftStartNumber),
                endShiftInfo: selectedGroupInfo?.shiftEndNumber && state.templateShifts?.find(vs => vs.shiftNumber == selectedGroupInfo.shiftEndNumber)
            }
                : null;
            const groups = templateInfo ? templateInfo.mxGroups.map(grp => { return { "mxVisitGroupId": grp.groupId, "groupName": grp.groupName } })?.filter(grpInfo => grpInfo.groupName !== "OTHERS") : [];
            return {
                ...state,
                isLoadingTemplateDetails: false,
                templateDetails: templateInfo,
                templateMilestones: templateInfo ? templateInfo.mxGroups?.filter(group => group.groupName !== "OTHERS") : [],
                otherMilestone: templateInfo ? templateInfo.mxGroups?.find(group => group.groupName === "OTHERS") : null,
                templateShifts: templateInfo ? getTemplateShifts(templateInfo.duration) : null,
                selectedTemplateGroup: selectedGroup,
                crewGroups: groups
            }
        }
        case 'SET_LOADING_TEMPLATE':
            return{
                ...state,
                isLoadingTemplateDetails:action.payload
            }
        case `${RESET_TEMPLATE_DETAILS}`: {
            return {
                ...state,
                isLoadingTemplateDetails: true,
                templateDetails: action.payload
            }
        }
        case `${SET_SELECTED_TEMPLATE_GROUP}`: {
            const grpInfo = action.payload && state.templateDetails ? state.templateDetails.mxGroups.find(grp => grp.groupId === action.payload) : null
            return {
                ...state,
                selectedTemplateGroup: {
                    groupId: action.payload,
                    isScheduled: grpInfo?.shiftStartNumber !== null && grpInfo?.shiftStartNumber !== 0 && grpInfo?.shiftEndNumber !== null && grpInfo?.shiftEndNumber !== 0,
                    groupInfo: grpInfo ? grpInfo : null,
                    startShiftInfo: grpInfo?.shiftStartNumber && state.templateShifts?.find(vs => vs.shiftNumber == grpInfo.shiftStartNumber),
                    endShiftInfo: grpInfo?.shiftEndNumber && state.templateShifts?.find(vs => vs.shiftNumber == grpInfo.shiftEndNumber)
                }
            }
        }
        case `${GET_TEMPLATE_TASKCARD_DETAILS}_PENDING`: {
            return {
                ...state,
                isLoadingTaskcardDetails: true,
                taskcardDetails: null
            }
        }
        case `${GET_TEMPLATE_TASKCARD_DETAILS}_FULFILLED`: {
            let templateTaskCardDetails = (action.payload && action.payload.getTemplateTaskCardDetails) ? action.payload.getTemplateTaskCardDetails : null;
            return {
                ...state,
                isLoadingTaskcardDetails: false,
                taskcardDetails: templateTaskCardDetails,
                templateShifts: templateTaskCardDetails ? getTemplateShifts(templateTaskCardDetails.taskCardDetailsOverview.duration) : null
            }
        }
        case `${SEARCH_TASKCARDS}_PENDING`: {
            return {
                ...state,
                isSearchingTaskcards: true,
                taskCardResults: null
            }
        }
        case `${SEARCH_TASKCARDS}_FULFILLED`: {
            let taskCardsInfo = (action.payload && action.payload.searchTaskcardsMultiSelect) ? action.payload.searchTaskcardsMultiSelect : null;
            return {
                ...state,
                isSearchingTaskcards: false,
                taskCardResults: taskCardsInfo
            }
        }
        case `${SEARCH_TASKCARDS_ENHANCED}_PENDING`: {
            return {
                ...state,
                isSearchingTaskcards: true,
                taskCardResults: null
            }
        }
        case `${SEARCH_TASKCARDS_ENHANCED}_FULFILLED`: {
            let taskCardsInfo = (action.payload && action.payload.searchTaskcardsMultiSelectEnhanced) ? action.payload.searchTaskcardsMultiSelectEnhanced : null;
            return {
                ...state,
                isSearchingTaskcards: false,
                taskCardResults: taskCardsInfo
            }
        }
        case `${SAVE_TEMPLATE_TASKCARD_DETAILS}_PENDING`: {
            return {
                ...state,
                isTaskcardDetailsSavePending: true,
                taskcardDetailsErrorMessage: null,
                isLoadingTaskcardDetails: true
            }
        }
        case `${SAVE_TEMPLATE_TASKCARD_DETAILS}_FULFILLED`: {
            return {
                ...state,
                isTaskcardDetailsSavePending: false,
                isSavetaskcardDetailsSuccess: action.payload && action.payload.saveTemplateTaskCardDetails.isSuccess,
                isSavetaskcardDetailsError: action.payload && action.payload.saveTemplateTaskCardDetails.error ? true : false,
                taskcardDetailsErrorMessage: action.payload && action.payload.saveTemplateTaskCardDetails.error || null,
                isLoadingTaskcardDetails: false
            }
        }
        case `${GET_TEMPLATE_MANAGEMENT_REF_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingRefData: true,
                templateManagementRefData: null
            }
        }
        case `${GET_TEMPLATE_MANAGEMENT_REF_DATA}_FULFILLED`: {
            return {
                ...state,
                isLoadingRefData: false,
                templateManagementRefData: action.payload && action.payload.getRefData
            }
        }
        case `${GET_TEMPLATE_MANAGEMENT_GROUPS_DATA}_PENDING`: {
            return {
                ...state,
                isLoadingGroupsData: true,
                groupsData: null
            }
        }
        case `${GET_TEMPLATE_MANAGEMENT_GROUPS_DATA}_FULFILLED`: {
            return {
                ...state,
                isLoadingGroupsData: false,
                groupsData: action.payload && action.payload.getGroups
            }
        }
        case `${SAVE_UPDATE_MILESTONE}_PENDING`: {
            return {
                ...state,
                saveUpdateMilestoneResp: null,
                saveUpdateMilestoneLoading: true
            }
        }
        case `${SAVE_UPDATE_MILESTONE}_FULFILLED`: {
            return {
                ...state,
                saveUpdateMilestoneResp: action.payload ? action.payload.saveGroup : {
                    "groupId": 0,
                    "isSuccess": false,
                    "error": "Something went wrong, please try later"
                },
                saveUpdateMilestoneLoading: false
            }
        }
        case `${FETCH_TEMPLATE_MILESTONES}_PENDING`: {
            return {
                ...state,
                isLoadingTemplateMilestones: true,
                milestonesData: null
            }
        }
        case `${FETCH_TEMPLATE_MILESTONES}_FULFILLED`: {
            return {
                ...state,
                isLoadingTemplateMilestones: false,
                milestonesData: action.payload && action.payload?.getTemplateMilestones
            }
        }
        case `${RESET_SAVE_UPDATE_MILESTONE}`: {
            return {
                ...state,
                saveUpdateMilestoneResp: {},
                saveUpdateMilestoneLoading: false
            }
        }

        case `${RESET_TASK_CARD_DETAILS_ERROR_MESSAGE}`: {
            return {
                ...state,
                isSavetaskcardDetailsError: false,
                taskcardDetailsErrorMessage: null,
            }
        }

        case `${SET_TASKCARD_SEARCH_CRITERIA}`: {
            return {
                ...state,
                taskCardSearchCriteria: action.payload
            }
        }

        case `${RESET_TASKCARD_SEARCH_DATA}`: {
            return {
                ...state,
                isSearchingTaskcards: false,
                taskCardResults: null,
                taskCardSearchCriteria: {
                    searchType: { value: "Template", isValid: false },
                    taskCardNumber: { value: "", isValid: false },
                    description: { value: "", isValid: false },
                    skillIds: { value: [], isValid: false },
                    workBinIds: { value: [], isValid: false },
                    mileStoneIds: { value: [], isValid: false },
                    taskCardTypes: { value: [], isValid: false },
                    isSearchValid: false,
                    defaultSearchParams: [],
                    visitSearchParams: [],
                    zoneTypeIDs: { value: [], isValid: false }
                },
                milestonesData: null
            }
        }
        case `${APPLY_TEMPLATE}_PENDING`: {
            return {
                ...state,
                isApplyingTemplate: true,
                applyTemplateResponse: null
            }
        }
        case `${APPLY_TEMPLATE}_FULFILLED`: {
            return {
                ...state,
                isApplyingTemplate: false,
                applyTemplateResponse: action.payload?.applyTemplate ? action.payload.applyTemplate : { isSuccess: false, erros: [""] }
            }
        }
        case `${RESET_APPLY_TEMPLATE}`: {
            return {
                ...state,
                isApplyingTemplate: false,
                applyTemplateResponse: null
            }
        }
        case `${SAVE_TEMPLATE}_PENDING`: {
            return {
                ...state,
                isSavingTemplate: true,
                isSaveSuccess: false,
                savedTemplateId: null,
                isSaveTemplateError: false,
                saveTemplateErrorMessage: false
            }
        }
        case `${SAVE_TEMPLATE}_FULFILLED`: {
            return {
                ...state,
                isSavingTemplate: false,
                savedTemplateId: action.payload && action.payload.saveAsTemplate?.mxTemplateId,
                isSaveSuccess: action.payload && action.payload.saveAsTemplate?.isSuccess,
                isSaveTemplateError: action.payload && action.payload.saveAsTemplate?.error !== null ? true : false,
                saveTemplateErrorMessage: action.payload && action.payload.saveAsTemplate?.error
            }
        }
        case `${RESET_SAVE_TEMPLATE_ERROR_MESSAGE}`: {
            return {
                ...state,
                isSaveSuccess: false,
                isSavingTemplate: false,
                savedTemplateId: null,
                isSaveTemplateError: false,
                saveTemplateErrorMessage: null
            }
        }
        case `${SET_UPDATE_TEMPLATE_FLAG}`: {
            return {
                ...state,
                isUpdateTemplateModalView: action.payload
            }
        }
        case `${UPDATE_TEMPLATE}_PENDING`: {
            return {
                ...state,
                isUpdatingTemplate: true,
                updateTemplateResponse: null
            }
        }
        case `${UPDATE_TEMPLATE}_FULFILLED`: {
            return {
                ...state,
                isUpdatingTemplate: false,
                updateTemplateResponse: action.payload?.updateTemplateFromVisit
            }
        }
        case `${UPDATE_TEMPLATE_DETAILS}_PENDING`: {
            return {
                ...state,
                isSavingTemplate: true,
                isSaveSuccess: null
            }
        }
        case `${UPDATE_TEMPLATE_DETAILS}_FULFILLED`: {
            return {
                ...state,
                isSavingTemplate: false,
                isSaveSuccess: action.payload?.updateTemplateDetails?.isSuccess
            }
        }
        case `${DELETE_TEMPLATE}_FULFILLED`: {
            const templateList = state.templates !== null ? [...state.templates] : [];
            const updatedTemplates = templateList?.filter(template => template.mxTemplateID !== action.payload) || null;

            return {
                ...state,
                templates: updatedTemplates
            }
        }

        default: return state;
    }
}

export default templateReducer;