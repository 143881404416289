import {
   SET_GENERIC_ERROR, FETCH_LEAD_MODULE_CREWS, SET_SELECTED_LEAD_MODULE_CREW,
   ASSIGN_LEAD_ASSOCIATES_TECH, SET_NOTIFICATION_MESSAGE, FETCH_CREW_WORKLOAD_LEAD_MODULE,
   FETCH_OPENE_NONROUTINES_LEAD_MODULE, UPDATE_ASSIGNEES, UPDATE_TASK_CARD_ASSIGNEES,
   UPDATE_LOGPAGE_ASSIGNEES, UPDATE_OPEN_LOGPAGE_ASSIGNEES, SET_PLANNING_CALL, RESET_MYSHIFT_DATA,
   SET_LAST_REFRESHED, UPDATE_TASK_CARD_ASSIGNEES_DUP
} from '../actions/actionTypes';
import * as Queries from '../queries/myShiftQueries';
import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import { executeQuery } from 'store/helper';

export const resetMyShiftData = () => {
   return dispatch => {
      dispatch({
         type: RESET_MYSHIFT_DATA
      })
   }
}

export const fetchLeadModuleCrews = (aircraftInfo, profile) => {
   return (dispatch, getState) => {
      let crews = {
         "crews":
         {
            "station": aircraftInfo.station,
            "baseStation": aircraftInfo.station,
            "date": moment().format('MM/DD/YYYY HH:mm:ss'),
            "employeeID": profile.userId,
            "jobRole": profile.jobRole,
            "tailNumber": aircraftInfo.aircraftNumber,
            "flightNumber": "0000",
            "scheduledArrivalDate": aircraftInfo.actualInductionDate || aircraftInfo.plannedActualDate
         }
      };
      dispatch({
         type: FETCH_LEAD_MODULE_CREWS,
         payload: executeQuery(Queries.fetchLeadModuleCrews(), crews, getState(), 'TemplateManagement')
      }).then(resp => {
         if (resp !== null) {
            dispatch({
               type: SET_LAST_REFRESHED,
               payload: Date.now()
            })
         }
      })
         .catch(error => {
            dispatch({
               type: SET_GENERIC_ERROR,
               payload: error?.message
            })
         });
   }
}

export const setSelectedLeadModuleCrew = (crewConfigId, isFromSHR = false) => {
   return (dispatch, getState) => {
      const loadModuleCrewsDetails = getState() && getState().myShiftStore.loadModuleCrewsDetails;
      const selectedCrew = loadModuleCrewsDetails?.find(crew => crew.crewConfigId === crewConfigId);
      dispatch({
         type: SET_SELECTED_LEAD_MODULE_CREW,
         payload: selectedCrew
      });
      if (selectedCrew) {
         if (isFromSHR) {
            dispatch(fetchCrewWorkloadForLeadModule(selectedCrew, isFromSHR));
         } else {
            dispatch(fetchCrewWorkloadForLeadModule(selectedCrew));
            dispatch(fetchNonRoutineForLeadModule(selectedCrew));
         }
      }
   }
}

export const fetchCrewWorkloadForLeadModule = (selectedCrew, isFromSHR = false) => {
   return (dispatch, getState) => {
      const visitInfo = getState() && getState().visitsStore.visitInfo;
      const profile = getState() && getState().profileStore.userProfile;
      let reqVar = {
         "crewWorkload": {
            "tailnumber": visitInfo.aircraftNumber,
            "baseStation": visitInfo.station,
            "station": visitInfo.station,
            "jobRole": profile.jobRole,
            "isFlifo": false,
            "isLineAircraft": false,
            "enableVS": visitInfo.enableVS,
            "isHvyChkPackage": visitInfo.enableZonalCard,
            "isHistoricalVisit": visitInfo.isArchived,
            "visitId": visitInfo.visitId,
            "crewCode": selectedCrew.crewCode,
            "crewConfigId": selectedCrew.crewConfigId,
            "bidArea": selectedCrew.bidArea,
            "employeeId": profile.userId,
            "crewDescription": selectedCrew.crewDescription,
            "shiftName": selectedCrew.shiftName,
            "shiftEndDate": selectedCrew.shiftEndDate,
            "shiftEndDateUTC": selectedCrew.shiftEndDateUTC,
            "shiftStartDate": selectedCrew.shiftStartDate,
            "shiftStartDateUTC": selectedCrew.shiftStartDateUTC,
            "getEtaskWorkload": {
               "inFlightNumber": "0000",
               "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
               "departureDate": visitInfo.plannedETRDate
            },
            "technicians": selectedCrew.crewTechnicianAttrs.map(tech => {
               return {
                  empId: tech.employeeId,
                  firstName: tech.firstName,
                  lastName: tech.lastName
               }
            })
         }
      };
      if (isFromSHR) {
         reqVar.crewWorkload.isShiftHandoverRequest = true;
      }
      dispatch({
         type: FETCH_CREW_WORKLOAD_LEAD_MODULE,
         payload: executeQuery(Queries.fetchCrewWorkloadForLeadModule(isFromSHR), reqVar, getState(), "CrewUpgrade")
      }).then(resp => {
         if (resp !== null) {
            dispatch({
               type: SET_LAST_REFRESHED,
               payload: Date.now()
            })
         }
      })
         .catch(error => {
            dispatch({
               type: SET_GENERIC_ERROR,
               payload: error?.message
            })
         });
   }
}

export const assignAssociatesTech = (techs, selectedCrew, workItems, profile, type, taskcardIdentifier, isMyshift = true, isDup=false, isCascadingEnabled) => {
   return (dispatch, getState) => {
      const aircraftInfo = getState() && getState().visitsStore.aircraftInfo;
      const leadAssociatesTech = {
         "leadAssociateTechs": {
            "crewConfigId": selectedCrew.crewConfigId,
            "crewCode": selectedCrew.crewCode,
            "crewDescription": selectedCrew.crewDescription,
            "crewName": selectedCrew.crewName,
            "bidArea": selectedCrew.bidArea,
            "tailWorkItems": workItems?.map(wi => {
               return {
                  "tailNumber": aircraftInfo.aircraftNumber,
                  "station": aircraftInfo.station,
                  "scheduledArrival": aircraftInfo.arrivalDate,
                  "scheduledDeparture": aircraftInfo.departureDate,
                  "serialNumber": null, // Assuming that NR's, LP's doesnot have serial number so you have send null for taskcards looks like we have a serial number , assign that here
                  ...wi,
                  "isInductedAC": true, //for base AC it is always true   
               }
            }) ,
            "tailNumber": aircraftInfo.aircraftNumber,
            "station": aircraftInfo.station,
            "techs": [...techs].map(item => ({
               empId: item.employeeId,
               firstName: item.firstName,
               lastName: item.lastName,
               isAssign: item.isAssign
            })),
            "assignedDate": moment().format('MMM DD, YYYY'),
            "shiftName": selectedCrew.shiftName,
            "createdBy": profile.userId,
            "isInductedAC": true,
            "scheduledArrival": aircraftInfo.arrivalDate,
            "scheduledDeparture": aircraftInfo.departureDate
         }
      };
      return dispatch({
         type: ASSIGN_LEAD_ASSOCIATES_TECH,
         payload: executeQuery(Queries.assignAssociateTechs(), leadAssociatesTech, getState(), 'CrewUpgrade')
      }).then(resp => {
         const techsSelected = resp.value.leadAssociateTechsToWork?.technicianAssigned || [];
         // if (resp.value.leadAssociateTechsToWork.isSuccess) {
         dispatch({
            type: SET_NOTIFICATION_MESSAGE,
            payload: "Published Assignment Successfully"
         });

         const unAssignedUsers = techs.filter(user => !user.isAssign).reduce((acc, cur) => [...acc, cur.employeeId || cur.id], [])
         const workItem = workItems[0];
         if (isMyshift) {            
            //  Get the un assigned user list 
            //  Conditional dispatch based on the type 
            switch (type.toLowerCase()) {
               case 'nonroutine':
                  dispatch({
                     type: UPDATE_ASSIGNEES,
                     payload: { id: workItem.mxWorkItem, techs: techsSelected, unAssignedUsers }
                  });
                  break;
               case 'taskcard':
                  if (isDup && isCascadingEnabled) {
                     dispatch({
                        type: UPDATE_TASK_CARD_ASSIGNEES_DUP,
                        payload: { id: taskcardIdentifier, workItems: workItems, techs: techsSelected, key: workItem['mxSubWorkItemType'] === 'D1' ? 'dash1Number' : 'cardId', taskcardType: workItem['mxSubWorkItemType'] === 'D1' ? 'etaskCards' : 'zonalCards', unAssignedUsers: unAssignedUsers ?? [] }
                     })
                  }
                  else {
                     dispatch({
                        type: UPDATE_TASK_CARD_ASSIGNEES,
                        payload: { id: taskcardIdentifier, techs: techsSelected, key: workItem['mxSubWorkItemType'] === 'D1' ? 'dash1Number' : 'cardId', taskcardType: workItem['mxSubWorkItemType'] === 'D1' ? 'etaskCards' : 'zonalCards', unAssignedUsers: unAssignedUsers ?? [] }
                     })
                  }

                  break;
               case 'papercard':
                  dispatch({
                     type: UPDATE_TASK_CARD_ASSIGNEES,
                     payload: { id: taskcardIdentifier, techs: techsSelected, key: workItem['mxSubWorkItemType'] === 'D1' ? 'dash1Number' : 'cardId', taskcardType: 'paperTaskCards', unAssignedUsers }
                  })

                  break;
               case 'logpages':
               case 'logpage':
                  dispatch({
                     type: UPDATE_LOGPAGE_ASSIGNEES,
                     payload: { id: workItem.mxWorkItem, techs: techsSelected, unAssignedUsers }
                  })
                  break;
               case 'openlogpage':
                  dispatch({
                     type: UPDATE_OPEN_LOGPAGE_ASSIGNEES,
                     payload: { id: workItem.mxWorkItem, techs: techsSelected, unAssignedUsers }
                  })
                  break;
            }
         } else {
            switch (type.toLowerCase()) {
               case 'nonroutine':
                  dispatch({
                     type: 'UPDATE_WL_NR_ASSIGNEES',
                     payload: { techsSelected, workItems: workItem, unAssignedUsers }
                  });
                  break;
               case 'taskcard':
                  if (isDup && isCascadingEnabled) {
                     dispatch({
                        type: 'UPDATE_WL_TASKCARD_ASSIGNEES_DUP',
                        payload: { techsSelected, workItems, unAssignedUsers, cardType: 'taskcard' }
                     })
                  }
                  else {
                     dispatch({
                        type: 'UPDATE_WL_TASKCARD_ASSIGNEES',
                        payload: { techsSelected, workItems: workItem, unAssignedUsers, cardType: 'taskcard' }
                     })
                  }

                  break;
               case 'papercard':
                  dispatch({
                     type: 'UPDATE_WL_PAPERCARD_ASSIGNEES',
                     payload: { techsSelected, workItems: workItem, unAssignedUsers, cardType: 'papercard' }
                  })

                  break;
               case 'logpages':
               case 'logpage':
                  dispatch({
                     type: 'UPDATE_WL_LP_ASSIGNEES',
                     payload: { techsSelected, workItems: workItem, unAssignedUsers }
                  })
                  break;
               case 'openlogpage':
                  dispatch({
                     type: 'UPDATE_WL_OLP_ASSIGNEES',
                     payload: { techsSelected, workItems: workItem, unAssignedUsers }
                  })
                  break;
            }
         }
         return true
         // }
      })
         .catch(error => {
            dispatch({
               type: SET_GENERIC_ERROR,
               payload: error?.message
            })
         });
   }
}

export const fetchNonRoutineForLeadModule = (selectedCrew) => {
   return (dispatch, getState) => {
      const visitInfo = getState() && getState().visitsStore.visitInfo;
      const profile = getState() && getState().profileStore.userProfile;
      let reqVar = {
         "openE": {
            "visitId": visitInfo.visitId,
            "tailnumber": visitInfo.aircraftNumber,
            "station": visitInfo.station,
            "enableVS": visitInfo.enableVS,
            "isHvyChkPackage": visitInfo.enableZonalCard,
            "crewCode": selectedCrew.crewCode,
            "crewConfigID": selectedCrew.crewConfigId,
            "bidArea": selectedCrew.bidArea,
            "crewDescription": selectedCrew.crewDescription,
            "flightNumber": "0000",
            "schedArrDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
            "departureDate": visitInfo.plannedETRDate,
            "technicians": selectedCrew.crewTechnicianAttrs.map(tech => {
               return {
                  empId: tech.employeeId,
                  firstName: tech.firstName,
                  lastName: tech.lastName
               }
            })
         }
      }
      dispatch({
         type: FETCH_OPENE_NONROUTINES_LEAD_MODULE,
         payload: executeQuery(Queries.fetchOpenENonRoutinesForLeadModule(), reqVar, getState(), "CrewUpgrade")
      })
   }
}

export const updateActiveTab = (activeTab) => dispatch => dispatch({
   type: 'UPDATE_ACTIVE_TAB',
   payload: activeTab
})

export const updateViewByTechToggle = (isViewByTech) => dispatch => dispatch({
   type: 'UPDATE_VIEW_BY_TECH',
   payload: isViewByTech
})

export const updateUpcomingWork = (isUpcomingWork) => dispatch => dispatch({
   type: 'UPDATE_UPCOMING_WORK',
   payload: isUpcomingWork
})

export const updateUnAssignedWorkCount = (count) => dispatch => dispatch({
   type: 'UPDATE_UNASSIGNED_WORKLOAD_COUNT',
   payload: count
})

export const setPlanningCall = (nr, dash1) => dispatch => dispatch({
   type: SET_PLANNING_CALL,
   payload: (nr || dash1) ? { "nr": nr, "dash1": dash1 } : null
})